'use client';

import { useSetAtom } from 'jotai';
import Link from 'next/link';
import { useState } from 'react';
import { USER_CONSENT_COOKIE_KEY } from '../../../constants';
import isCookiesAccepted from '@/store/cookies';
import Button, { ButtonVariants } from './Button';
import styles from './CookiesPolicyBanner.module.scss';

const CookiesPolicyBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const setCookiesAccepted = useSetAtom(isCookiesAccepted);

  // useEffect(() => {
  // const isAskedBefore: boolean = !!localStorage.getItem(USER_CONSENT_COOKIE_KEY);
  // if (!isAskedBefore && pathname !== '/cookies-policy') {
  //   setTimeout(() => setIsModalVisible(true), 5000);
  // }
  // }, []);

  const acceptCookies = (): void => {
    localStorage.setItem(USER_CONSENT_COOKIE_KEY, '1');
    setCookiesAccepted(true);
    setIsModalVisible(false);
  };

  const rejectCookies = (): void => {
    localStorage.setItem(USER_CONSENT_COOKIE_KEY, '0');
    setCookiesAccepted(false);
    setIsModalVisible(false);
  };

  if (!isModalVisible) {
    return null;
  }

  return (
    <article className={styles.cookiesPolicyBanner}>
      <h5 className={styles.title}>
        <img
          src='/media/cookies.svg'
          alt=''
        />
        {' '}
        Our website uses cookies
      </h5>
      <p className={styles.text}>
        This website uses cookies for personalization, analytics, and advertising.
        If you continue to use our website, you consent to our
        {' '}
        <Link
          href='privacy_policy'
          target='_blank'
        >
          Privacy Policy
        </Link>
        .
      </p>
      <div className={styles.actions}>
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={acceptCookies}
        >
          Accept
        </Button>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={rejectCookies}
        >
          Decline
        </Button>
      </div>
    </article>
  );
};

export default CookiesPolicyBanner;
